import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useCustomMutation,
} from "@refinedev/core";
import { Show, DateField, ImageField } from "@refinedev/antd";
import { Descriptions, Typography, Select, Button, Space } from "antd";
import { API_URL } from "config/axios";

const { Title } = Typography;

export const ShowBooking: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const { mutate } = useCustomMutation();

  const [status, setStatus] = useState("");

  const record = data?.data;

  useEffect(() => {
    setStatus(record?.status);
  }, [record]);

  const updateStatus = async () => {
    mutate({
      url: `/bookings/update_status/${record?.id}`,
      method: "put",
      values: { status: status },
      successNotification: (data, values) => {
        return {
          message: `อัพเดทสถานะเรียบร้อยแล้ว`,
          type: "success",
        };
      },
    });
  };

  return (
    <Show isLoading={false} title="รายละเอียดการจอง" canEdit={false}>
      <Descriptions
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>ข้อมูลผู้จอง</span>
            <Space>
              <span style={{ fontWeight: "600", fontSize: 16 }}>สถานะ : </span>
              <Select
                value={status}
                style={{ width: 200 }}
                onChange={setStatus}
                options={[
                  { value: "pending", label: "กำลังตรวจสอบ" },
                  { value: "approved", label: "อนุมัติแล้ว" },
                  { value: "cancelled", label: "ยกเลิก" },
                ]}
              />
              <Button type="primary" onClick={updateStatus}>
                บันทึก
              </Button>
            </Space>
          </div>
        }
        layout="vertical"
      >
        <Descriptions.Item label="ชื่อผู้จอง">
          {record?.customer_name}
        </Descriptions.Item>
        <Descriptions.Item label="อีเมล์">
          {record?.customer_email}
        </Descriptions.Item>
        <Descriptions.Item label="เบอร์โทรศัพท์">
          {record?.customer_phone}
        </Descriptions.Item>
        <Descriptions.Item label="วันเดินทาง">
          <DateField value={record?.tour_date} format="D/MM/BBBB" />
        </Descriptions.Item>
        <Descriptions.Item label="จำนวนท่าน">
          {record?.quantity}
        </Descriptions.Item>
        <Descriptions.Item label="หมายเหตุ">{record?.note}</Descriptions.Item>
      </Descriptions>
      <br />
      <br />
      <br />
      <Descriptions title="ข้อมูลผู้แพคเกจ" layout="vertical">
        <Descriptions.Item label="ไอดีแพ็คเกจ">
          {record?.package?.id}
        </Descriptions.Item>
        <Descriptions.Item label="ชื่อแพ็คเกจ">
          {record?.package?.name}
        </Descriptions.Item>
        <Descriptions.Item label="รูปแบบ">
          {record?.package?.period}
        </Descriptions.Item>
        <Descriptions.Item label="ไกด์ท้องถิ่น">
          {record?.local_guide?.name}
        </Descriptions.Item>
        <Descriptions.Item label="การชำระเงิน (บาท)">
          {record?.price}
        </Descriptions.Item>
        <Descriptions.Item label="หลักฐานการชำระเงิน (สลิป)">
          {record?.slip ? (
            <ImageField value={API_URL + record?.slip?.asset} width={100} />
          ) : (
            "ยังไม่ได้ชำระเงิน"
          )}
        </Descriptions.Item>
      </Descriptions>
    </Show>
  );
};
function goBack() {
  throw new Error("Function not implemented.");
}
