import { useList } from "@refinedev/core";
import { Card } from "antd";
import { TopIncomes } from "./TopIncomes";
import { TopPackage } from "./TopPackage";
import { TopProduct } from "./TopProduct";

const user_id = JSON.parse(
  localStorage.getItem("permission") || "{}"
).app_able_id;

function IncomeCard() {
  const { data: keys } = useList({
    resource: `${user_id}/partner_analytics/income`,
    queryOptions: {
      select: (data: any) => {
        let keys = Object.keys(data.data);
        data = keys;
        return data;
      },
    },
  });

  const items = [
    {
      key: "top_incomes",
      label: "ยอดขายตามเอกสาร",
      children: <TopIncomes />,
    },
    {
      key: "top_packages",
      label: "ยอดขายตามคำสั่งซื้อ",
      children: <TopPackage />,
    },
    {
      key: "top_products",
      label: "ยอดขายตามคำสั่งซื้อ",
      children: <TopProduct />,
    },
  ];
  return (
    <Card
      bordered={false}
      style={{ height: "100%" }}
      tabList={keys ? items.filter((item) => keys.includes(item.key)) : []}
      headStyle={{ border: "none" }}
    />
  );
}

export default IncomeCard;
