import React from "react";
import {
  IResourceComponentsProps,
  useShow,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  DateField,
} from "@refinedev/antd";
import { Typography, Descriptions } from "antd";

import styles from "./Table.module.css";
import { IncomeItemsType } from "types";

export const IncomesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show title={`รายรับเลขที่ ${record?.no}`} isLoading={isLoading}>
      <Descriptions title="ข้อมูลลูกค้า" column={2}>
        <Descriptions.Item label="ชื่อลูกค้า">
          {record?.customer_name}
        </Descriptions.Item>
        <Descriptions.Item label="วันที่">
          <DateField value={record?.created_at} />
        </Descriptions.Item>
        <Descriptions.Item label="รายละเอียดลููกค้า">
          {record?.customer_detail}
        </Descriptions.Item>
        <Descriptions.Item label="รวมจำนวนเงินทั้งสิ้น">
          ฿
          <NumberField
            value={record?.total}
            //   style={{
            //     fontSize: 48,
            //     color: "#1890FF",
            //     fontWeight: "700",
            //     lineHeight: "0",
            //     paddingBottom: 30,
            //   }}
          />&nbsp; บาท
        </Descriptions.Item>
       
      </Descriptions>

      <Descriptions title="ข้อมูลโปรเจ็ค" column={2}>
        <Descriptions.Item label="ชื่อโปรเจ็ค">
          {record?.project_name}
        </Descriptions.Item>
        <Descriptions.Item label="เลขที่อ้างอิง">
          {record?.ref_no}
        </Descriptions.Item>
        <Descriptions.Item label="รายละเอียดโปรเจ็ค" span={2}>
          {record?.project_details}
        </Descriptions.Item>
      </Descriptions>
      <table className={styles.table}>
        <tr>
          <th>ลำดับ</th>
          <th>รายละเอียด</th>
          <th>จำนวน</th>
          <th>ราคาต่อหน่วย</th>
          <th>ราคารวม</th>
        </tr>
        {record?.income_items.map((item: IncomeItemsType, index: number) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.detail}</td>
            <td>{item.quantity}</td>
            <td>
              <NumberField value={item.price} />
            </td>
            <td>
              <NumberField value={item.total_price} />
            </td>
          </tr>
        ))}
      </table>
      <Descriptions column={2} style={{ marginTop: 15 }}>
        <Descriptions.Item label="ส่วนลดพิเศษ">
          <NumberField value={record?.discount} />
        </Descriptions.Item>
        <Descriptions.Item label="หมายเหตุ">{record?.note}</Descriptions.Item>
      </Descriptions>
    </Show>
  );
};
