import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  BooleanField,
  DateField,
  EditButton,
  ShowButton,
  DeleteButton,
  NumberField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const ExpensesList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="created_at"
          title="วันที่"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column dataIndex="no" title="เลขที่เอกสาร" />
        <Table.Column dataIndex="seller_name" title="ชื่อผู้จำหน่าย" />
        <Table.Column dataIndex="project_name" title="ชื่อโปรเจ็ค" />
        <Table.Column
          dataIndex="total"
          title="ยอดรวมสุทธิ"
          render={(text) => <NumberField value={text} />}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    //   render: (text) => <a>{text}</a>,
  },
];
