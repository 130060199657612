import { DashboardOutlined, DollarOutlined } from "@ant-design/icons";

const restaurant_id = localStorage.getItem("restaurant_id");
export const RestaurantResource = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "แดชบอร์ด",
      icon: <DashboardOutlined />,
    },
  },
  {
    name: `business_partners`,
    list: `/business_partners/show/${restaurant_id}`,
    edit: `/business_partners/edit/${restaurant_id}`,
    meta: {
      label: "ข้อมูลร้านอาหาร",
    },
  },
  {
    name: `finance`,
    list: `/business_partners/edit/${restaurant_id}`,
    meta: {
      label: "การเงิน",
      icon: <DollarOutlined />,
    },
  },
  {
    name: `${restaurant_id}/incomes`,
    list: `/restaurant/incomes`,
    create: "/restaurant/incomes/create",
    edit: "/restaurant/incomes/edit/:id",
    show: "/restaurant/incomes/show/:id",
    meta: {
      label: "รายรับ",
      parent: "finance",
    },
  },
  {
    name: `${restaurant_id}/expenses`,
    list: `/restaurant/expenses`,
    create: "/restaurant/expenses/create",
    edit: "/restaurant/expenses/edit/:id",
    show: "/restaurant/expenses/show/:id",
    meta: {
      label: "รายจ่าย",
      parent: "finance",
    },
  },
  {
    name: "tani_events",
    list: "/events",
    create: "/events/create",
    edit: "/events/edit/:id",
    show: "/events/show/:id",
    meta: {
      label: "กิจกรรม",
    },
  },
];
