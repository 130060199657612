import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, Form, message } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import axiosInstance, { API_URL } from "config/axios";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface props {
  id?: any;
  label: string;
  name: any;
  value?: any;
}

const UploadMultiple: React.FC<props> = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (props?.value?.length > 0) {
      const { value } = props;
      const filelist = value.map((item: any) => {
        return { ...item, thumbUrl: `${API_URL}${item?.asset}`, uid: item.id };
      });
      setFileList(filelist);
    }
  }, [props.value]);

  // console.log(fileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.name}
        rules={[
          {
            required: false,
          },
        ]}
        getValueFromEvent={(e: any) =>
          e.fileList.map((file: any) => (file.response ? file.response : file))
        }
      >
        <Upload
          name="asset"
          action={`${API_URL}/attachments`}
          headers={{
            Authorization: `Bearer ${sessionStorage.getItem("refine-auth")}`,
          }}
          beforeUpload={beforeUpload}
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          multiple
          showUploadList={{ showPreviewIcon: false }}
          onRemove={async (e: any) => {
            if (e.response) {
              const res = await axiosInstance.delete(
                `/attachments/${e?.response.id}`
              );
              return res.status === 204 ? true : false;
            }
          }}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadMultiple;
