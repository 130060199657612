import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, InputNumber, Row, Col } from "antd";
import AppTag from "components/AppTag";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

const { TextArea } = Input;

interface IRestaurant {
  name: string;
  address: string;
  embed_map: string;
  destination_visit_id: number;
  description: string;
  images: string[];
  contents: string;
}

export const CreateShopProduct: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const handleFinish = (values: any) => {
    const payload = {
      product: {
        ...values,
      },
    };
    onFinish(payload);
  };

  return (
    <Create title="เพิ่มสินค้า" saveButtonProps={saveButtonProps}>
      <Form {...formProps} onFinish={handleFinish} layout="vertical">
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="ชื่อสินค้า"
              name={["name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="SKU(รหัสสินค้า)"
              name={["sku"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <UploadMultiple label="รูปภาพ" name={["images"]} />
          </Col>
          <Col span={24}>
            <Form.Item
              label="รายละเอียด"
              name={["details"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              {/* @ts-ignore */}
              <TextEditor />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="จำนวนคงคลัง"
              name={["stock"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" placeholder="ระบุจำนวน" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="ราคาสินค้า"
              name={["price"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder="ระบุราคา"
              />
            </Form.Item>
          </Col>
          <Col offset={12} span={12}>
            <Form.Item
              label="ราคาก่อนลด"
              name={["price_before_discount"]}
              dependencies={["price"]}
              rules={[
                {
                  required: false,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value > getFieldValue("price")) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("ราคาโปรโมชั่นต้องน้อยกว่าราคาปกติ")
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder="ระบุราคา"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
