import React from "react";
import { Row, Col, Typography } from "antd";
import { DashboardTotalCountCard } from "./components/CountWidget";
import { PartnerCostCard } from "./components/PartnerCost";
import { PopularProductCard } from "./components/Product";
import CustomerTotalCard from "./components/Customer";
import { SalesCard } from "./components/SalesCard";
import { CostCard } from "./components/CostCard";
import { IncomeExpenseCard } from "./components/IncomeExpenseCard";
import ViewCard from "./components/ViewCard";
import IncomeCard from "./components/Incomes";

export const DashboardPage: React.FC = () => {
  return (
    <>
      <Row gutter={[24, 32]}>
        <Col span={12}>
          <IncomeCard />
        </Col>
        <Col span={12}>
          <CostCard />
        </Col>
        <Col span={24}>
          <IncomeExpenseCard />
        </Col>
        <Col span={24}>
          <ViewCard />
        </Col>
      </Row>
    </>
  );
};
