import React, { useState } from "react";
import { useList } from "@refinedev/core";
import { Card, Empty } from "antd";
import { Pie } from "@ant-design/charts";
import { SelectDate } from "./SelectDate";

const user_id = JSON.parse(
  localStorage.getItem("permission") || "{}"
).app_able_id;

export const CostCard: React.FC = () => {
  const [dateString, setDateString] = useState("");
  const { data: expenses } = useList({
    resource: `${user_id}/partner_analytics/expense?${dateString}`,
    queryOptions: {
      select: (data: any) => {
        return data.data.top_expenses;
      },
    },
  });

  const config = {
    data: expenses as any,
    angleField: "total",
    colorField: "name",
    paddingRight: 80,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };
  return (
    <Card bordered={false} title="ค่าใช้จ่ายตามหมวดหมู่">
      <SelectDate value={dateString} onChange={setDateString} />
      {!expenses && "กำลังโหลดกราฟ"}
      {expenses?.length > 0 ? (
        <div style={{ width: 400, margin: "auto" }}>
          <Pie {...config} />
        </div>
      ) : (
        <Empty description="ไม่มีข้อมูล" />
      )}
      <br />
    </Card>
  );
};
