import React from "react";
import { IResourceComponentsProps, useParsed } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Row, Col, Select } from "antd";
import UploadOne from "components/UploadOne";
import UploadFile from "components/UploadFile";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

const { TextArea } = Input;

interface ISelect {
  id: number;
  name: string;
}

interface Props {
  label: {
    detail?: string;
  };
}

export const EditProfile: React.FC<
  IResourceComponentsProps | Props["label"]
> = (props) => {
  console.log({ props });
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm({
    queryOptions: {
      enabled: true,
    },
    action: "edit",
  });

  const data = queryResult?.data?.data;

  const handleEdit = async (values: any) => {
    let payload = {
      business_partner: {
        ...values,
      },
    };
    await onFinish(payload);
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title="ข้อมูลส่วนตัว"
      headerButtons={false}
    >
      <Form {...formProps} layout="vertical" onFinish={handleEdit}>
        <Row gutter={12}>
          <Col span={24}>
            <UploadOne
              label="โลโก้"
              name="logo"
              value={data?.logo}
              rules={[{ required: false }]}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="ชื่อธุรกิจ"
              rules={[{ required: false }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label="ที่อยู่"
              rules={[{ required: false }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="embed_map"
              label="Embed a map"
              rules={[{ required: false }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="detail"
              label="คำบรรยายสั้นๆ "
              rules={[{ required: false }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <UploadMultiple
              name={["images"]}
              label="รูปภาพ"
              value={data?.images}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              name="content"
              label="รายละเอียด "
              rules={[{ required: false }]}
            >
              {/* @ts-ignore */}
              <TextEditor />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="facebook"
              label="เฟสบุ๊ค"
              rules={[{ required: false }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="เบอร์โทร"
              rules={[{ required: false }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="id_card_pdf"
              label="สำเนาบัตรประชาชน"
              rules={[{ required: false }]}
              getValueFromEvent={(e: any) => e.file.response}
            >
              <UploadFile />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="house_registration_pdf"
              label="สำเนาทะเบียนบ้าน "
              rules={[{ required: false }]}
              getValueFromEvent={(e: any) => e.file.response}
            >
              <UploadFile />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="commercial_registration_pdf"
              label="ประกาศนียบัตร(ถ้ามี)"
              getValueFromEvent={(e: any) => e.file.response}
            >
              <UploadFile />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
