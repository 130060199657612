import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space, Button } from "antd";
import { getUserStatus } from "utils";

export const ListProduct: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["updated_at"]}
          title="วันที่เพิ่มสินค้า"
          render={(value: any) => (
            <DateField value={value} format="D/MM/BBBB" />
          )}
        />
        {/* <Table.Column dataIndex="id" title="ไอดี" /> */}
        <Table.Column dataIndex="name" title="ชื่อสินค้า" />
        <Table.Column dataIndex="sku" title="SKU(รหัสสินค้า)" />
        <Table.Column dataIndex="stock" title="สินค้าคงเหลือ" />
        <Table.Column
          dataIndex="price"
          title="ราคาสินค้า"
          render={(value) => `฿${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
        <Table.Column
          dataIndex="price_before_discount"
          title="ราคาก่อนลด"
          render={(text) => (text ? `฿${text}` : "ไม่มี")}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
