import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  TagField,
  EmailField,
  DateField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const ListBooking: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const status = [
    {
      type: "pending",
      label: "กำลังตรวจสอบ",
    },
    {
      type: "cancelled",
      label: "ยกเลิก",
    },
    {
      type: "approved",
      label: "อนุมัติแล้ว",
    },
  ];

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["created_at"]}
          title="วันที่จอง"
          render={(value: any) => (
            <DateField value={value} locales="th" format="D/MM/BBBB" />
          )}
        />
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="customer_name" title="ชื่อผู้จอง" />
        <Table.Column
          dataIndex="status"
          title="สถานะการจอง"
          render={(value) => status.find(({ type }) => type === value)?.label}
        />
        <Table.Column dataIndex={["package", "name"]} title="ชื่อแพ็คเกจ" />
        <Table.Column dataIndex="price" title="ราคา" />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
