import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { AntdInferencer } from "@refinedev/inferencer/antd";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  ErrorComponent,
  notificationProvider,
  ThemedLayoutV2,
  ThemedTitleV2,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";

//tour
import {
  CreatePackage,
  PackageList,
  EditPackage,
  ShowPackage,
} from "./pages/tour/package";
import { ListBooking, ShowBooking } from "./pages/tour/booking";
import { ReviewList } from "./pages/tour/review";

//restaurant
import { CreateRestaurant } from "./pages/restaurant";

import { CreateShopDetails } from "./pages/shops/detail";
import { CreateShopProduct, ListProduct } from "./pages/shops/products";
import { ListOrder, ShowOrder } from "./pages/shops/orders";
import { ShowFinancial, EditFinancial } from "./pages/shops/financial";

import { RegisterOnboarding } from "pages/onboarding";

import axiosInstance, { API_URL } from "./config/axios";
import { DashboardPage } from "pages/dashboard";
import { resources } from "resources";
import { EditProduct } from "pages/shops/products/edit";

import { HotelProfile } from "pages/hotel/profile";

import {
  ExpensesList,
  ExpensesCreate,
  ExpensesEdit,
  ExpensesShow,
} from "pages/expenses";

import {
  CreateBankAccount,
  EditBankAccount,
  ListBankAccount,
} from "pages/bank_accounts";

import AppIcon from "components/AppIcon";
import { EventsCreate, EventsEdit, EventsList, EventsShow } from "pages/events";
import {
  IncomesCreate,
  IncomesEdit,
  IncomesList,
  IncomesShow,
} from "pages/incomes";
import { ShowShopProduct } from "pages/shops/products/show";
import { ShowProfile } from "pages/profile/show";
import { EditProfile } from "pages/profile/edit";

const BUSSINESS_ID = localStorage.getItem("bussiness_id");

const BUSSINESS_TYPE = localStorage.getItem("bussiness_type");

const RESOURCES = resources.find(
  (item: any) => item.type === BUSSINESS_TYPE
)?.resources;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider(API_URL, axiosInstance)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            resources={RESOURCES}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  BUSSINESS_ID !== "null" ? (
                    <Navigate to="/" />
                  ) : (
                    <Authenticated
                      key="authenticated-routes"
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <Outlet />
                    </Authenticated>
                  )
                }
              >
                <Route
                  path="/onboarding/register"
                  element={<RegisterOnboarding />}
                />
              </Route>
              <Route
                element={
                  BUSSINESS_ID == "null" ? (
                    <Navigate to="/onboarding/register" />
                  ) : (
                    <Authenticated
                      key="authenticated-routes"
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2
                        Header={() => <Header sticky />}
                        Title={({ collapsed }) =>
                          collapsed ? <span>A</span> : <AppIcon />
                        }
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  )
                }
              >
                <Route
                  path="/"
                  element={
                    <div>
                      {/* <h1>ระบบจัดการข้อมูลสำหรับผู้ประกอบการ</h1> */}
                      <DashboardPage />
                    </div>
                  }
                />

                <Route
                  path="/business_partners/show/:id"
                  element={<ShowProfile />}
                />

                <Route
                  path="/business_partners/edit/:id"
                  element={<EditProfile />}
                />

                <Route path="/events">
                  <Route index element={<EventsList />} />
                  <Route path="create" element={<EventsCreate />} />
                  <Route path="edit/:id" element={<EventsEdit />} />
                  <Route path="show/:id" element={<EventsShow />} />
                </Route>

                <Route path="/packages">
                  <Route index element={<PackageList />} />
                  <Route path="create" element={<CreatePackage />} />
                  <Route path="edit/:id" element={<EditPackage />} />
                  <Route path="show/:id" element={<ShowPackage />} />
                  <Route path="reviews/:id" element={<ReviewList />} />
                </Route>

                <Route path="/bookings">
                  <Route index element={<ListBooking />} />
                  <Route path="show/:id" element={<ShowBooking />} />
                </Route>

                <Route path="/restaurant">
                  <Route path="create" element={<CreateRestaurant />} />
                  <Route path="expenses" element={<ExpensesList />} />
                  <Route path="expenses/create" element={<ExpensesCreate />} />
                  <Route path="expenses/edit/:id" element={<ExpensesEdit />} />
                  <Route path="expenses/show/:id" element={<ExpensesShow />} />
                  <Route path="incomes" element={<IncomesList />} />
                  <Route path="incomes/create" element={<IncomesCreate />} />
                  <Route path="incomes/edit/:id" element={<IncomesEdit />} />
                  <Route path="incomes/show/:id" element={<IncomesShow />} />
                </Route>

                <Route path="/hotel">
                  <Route path="edit/:id" element={<HotelProfile />} />
                  <Route path="expenses" element={<ExpensesList />} />
                  <Route path="expenses/create" element={<ExpensesCreate />} />
                  <Route path="expenses/edit/:id" element={<ExpensesEdit />} />
                  <Route path="expenses/show/:id" element={<ExpensesShow />} />
                  <Route path="incomes" element={<IncomesList />} />
                  <Route path="incomes/create" element={<IncomesCreate />} />
                  <Route path="incomes/edit/:id" element={<IncomesEdit />} />
                  <Route path="incomes/show/:id" element={<IncomesShow />} />
                </Route>

                <Route path="/tour">
                  <Route path="expenses" element={<ExpensesList />} />
                  <Route path="expenses/create" element={<ExpensesCreate />} />
                  <Route path="expenses/edit/:id" element={<ExpensesEdit />} />
                  <Route path="expenses/show/:id" element={<ExpensesShow />} />
                  <Route path="incomes" element={<IncomesList />} />
                  <Route path="incomes/create" element={<IncomesCreate />} />
                  <Route path="incomes/edit/:id" element={<IncomesEdit />} />
                  <Route path="incomes/show/:id" element={<IncomesShow />} />
                </Route>

                <Route path="/shop">
                  <Route path="expenses" element={<ExpensesList />} />
                  <Route path="expenses/create" element={<ExpensesCreate />} />
                  <Route path="expenses/edit/:id" element={<ExpensesEdit />} />
                  <Route path="expenses/show/:id" element={<ExpensesShow />} />
                  <Route path="incomes" element={<IncomesList />} />
                  <Route path="incomes/create" element={<IncomesCreate />} />
                  <Route path="incomes/edit/:id" element={<IncomesEdit />} />
                  <Route path="incomes/show/:id" element={<IncomesShow />} />
                </Route>

                <Route path="/other">
                  <Route path="expenses" element={<ExpensesList />} />
                  <Route path="expenses/create" element={<ExpensesCreate />} />
                  <Route path="expenses/edit/:id" element={<ExpensesEdit />} />
                  <Route path="expenses/show/:id" element={<ExpensesShow />} />
                  <Route path="incomes" element={<IncomesList />} />
                  <Route path="incomes/create" element={<IncomesCreate />} />
                  <Route path="incomes/edit/:id" element={<IncomesEdit />} />
                  <Route path="incomes/show/:id" element={<IncomesShow />} />
                </Route>

                <Route path="/shops/details">
                  <Route path="create" element={<CreateShopDetails />} />
                </Route>
                <Route path="/products">
                  <Route index element={<ListProduct />} />
                  <Route path="create" element={<CreateShopProduct />} />
                  <Route path="edit/:id" element={<EditProduct />} />
                  <Route path="show/:id" element={<ShowShopProduct />} />
                </Route>
                <Route path="/orders">
                  <Route index element={<ListOrder />} />
                  <Route path="show/:id" element={<ShowOrder />} />
                </Route>
                <Route path="/bank_accounts">
                  <Route index element={<ListBankAccount />} />
                  <Route path="create" element={<CreateBankAccount />} />
                  <Route path="edit/:id" element={<EditBankAccount />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated
                    key="unauthenticated-routes"
                    fallback={<Outlet />}
                  >
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;

// update git
