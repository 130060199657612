import React from "react";
import { useLogout, useNavigation } from "@refinedev/core";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from "./styles";

import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  CardProps,
  LayoutProps,
  Divider,
  FormProps,
  theme,
} from "antd";

const { Title } = Typography;

export const OnBoarding: React.FC = () => {
  const { mutate: logout } = useLogout();
  const { push } = useNavigation();

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <Card
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
              ...containerStyles,
            }}
          >
            <div
              style={{
                width: 500,
                height: 300,
                display: "flex",
                flexDirection: "column",
                gap: 12,
                justifyContent: "center",
              }}
            >
              <Title level={3} style={titleStyles}>
                ลงทะเบียนองค์กรของท่าน
              </Title>
              <Button
                style={{
                  width: 300,
                  height: 50,
                  margin: "0 auto",
                  backgroundColor: "#fa4155",
                  color: "white",
                }}
                onClick={() => {
                  push("/onboarding/existing");
                }}
              >
                มีองค์กรอยู่แล้ว
              </Button>
              <Button
                style={{ width: 300, height: 50, margin: "0 auto" }}
                onClick={() => {
                  push("/onboarding/register");
                }}
              >
                สมัครองค์กรใหม่
              </Button>
              <Button
                style={{
                  width: 300,
                  height: 50,
                  margin: "0 auto",
                  marginTop: 30,
                }}
                onClick={() => logout()}
              >
                ออกจากระบบ
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
