import React, { useEffect, useMemo, useState } from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm, NumberField, useSelect } from "@refinedev/antd";
import {
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Space,
  Button,
  Select,
  InputNumber,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { ExpenseItemsType } from "types";

import styles from "./Table.module.css";

const { TextArea } = Input;

interface ICategory {
  id: number;
  name: string;
}

export const ExpensesEdit: React.FC<IResourceComponentsProps> = () => {
  const [Total, setTotal] = useState(0);
  const [Tax, setTax] = useState(0);
  const { formProps, saveButtonProps, formLoading, form, onFinish } = useForm();

  const { selectProps } = useSelect<ICategory>({
    resource: "document_categories",
    optionLabel: "name",
    optionValue: "id",
  });

  const expense_items: ExpenseItemsType[] = Form.useWatch(
    "expense_items",
    form
  );

  const taxable: ExpenseItemsType[] = Form.useWatch("taxable", form);

  const newExpenseItem = useMemo(() => {
    if (expense_items && expense_items.length > 0) {
      const data = expense_items.map((item) => {
        if (item !== undefined) {
          item.total_price = item.quantity * item.price;
          return item;
        }
      });

      const sum_total = expense_items.reduce((acc, currentObject) => {
        if (currentObject !== undefined) {
          return acc + currentObject.total_price;
        }
        return 0;
      }, 0);

      setTotal(sum_total);

      return data;
    }
  }, [expense_items]);

  form.setFieldValue("expense_items", newExpenseItem);

  useEffect(() => {
    if (taxable) {
      const tax = (Total * 7) / 100;
      setTax((prev) => prev + tax);
    } else {
      setTax(0);
    }
  }, [taxable]);

  useEffect(() => {
    const total = Total + Tax;
    form.setFieldValue("total", total);
  }, [Total, Tax]);

  const handleSubmit = async (values: any) => {
    const payload = {
      expense: {
        ...values,
        expense_items: expense_items ?? [],
      },
    };
    await onFinish(payload);
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      title="แก้ไขรายจ่าย"
    >
      <Form {...formProps} onFinish={handleSubmit}>
        <Row gutter={30}>
          <Col md={12}>
            <Form.Item
              label="ชื่อผู้จำหน่าย"
              name={["seller_name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="วันที่"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="ข้อมูลจำหน่าย"
              name={["seller_detail"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="เครดิต(วัน)"
              name={["credit"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="จำนวนเงินรวมทั้งสิ้น"
              name={["taxable"]}
              valuePropName="checked"
              initialValue={false}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Space>
                <Checkbox />
                <span>ภาษีมูลค่าเพิ่ม 7%</span>
              </Space>
            </Form.Item>
            <Form.Item name={["total"]} hidden initialValue={0} />
            <NumberField
              value={Total + Tax}
              style={{
                fontSize: 48,
                color: "#1890FF",
                fontWeight: "700",
                lineHeight: "0",
                paddingBottom: 30,
              }}
            />
            <br />
            <br />
          </Col>
          <Col md={12}>
            <Form.Item
              label="ชื่อโปรเจ็ค"
              name={["project_name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="เลขที่อ้างอิง"
              name={["ref_no"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              label="รายละเอียดโปรเจ็ค"
              name={["project_detail"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.List name="expense_items">
              {(fields, { add, remove }) => (
                <>
                  <table className={styles.table}>
                    <tr>
                      {columns.map((column) => (
                        <th key={column.key}>{column.title}</th>
                      ))}
                    </tr>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <tr key={key}>
                        <td>
                          {key + 1}
                          <Form.Item
                            {...restField}
                            name={[name, "key"]}
                            initialValue={key}
                            hidden
                          />
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "detail"]}
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "document_category_id"]}
                            initialValue={2}
                          >
                            <Select
                              placeholder="Select a category"
                              {...selectProps}
                            />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "quantity"]}
                            initialValue={1}
                          >
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "price"]}
                            initialValue={0}
                          >
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "total_price"]}
                            initialValue={0}
                            hidden
                          >
                            <InputNumber disabled />
                          </Form.Item>
                          <NumberField
                            value={expense_items[index]?.total_price}
                          />
                        </td>
                        <div
                          style={{
                            position: "absolute",
                            top: 15,
                            marginLeft: 8,
                          }}
                        >
                          <DeleteOutlined
                            onClick={() => remove(name)}
                            style={{ color: "red" }}
                          />
                        </div>
                      </tr>
                    ))}
                  </table>
                  {fields.length === 0 && (
                    <div style={{ textAlign: "center", padding: "15px 0" }}>
                      ยังไม่มีรายการ
                    </div>
                  )}
                  <Button
                    type="primary"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ marginTop: 15, marginBottom: 30 }}
                  >
                    เพิ่มแถวรายการ
                  </Button>
                </>
              )}
            </Form.List>
          </Col>
          <Col md={12}>
            <Form.Item
              label="หมายเหตุ"
              name={["note"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

const columns = [
  {
    title: "ลำดับ",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "รายละเอียด",
    dataIndex: "detail",
    key: "detail",
  },
  {
    title: "หมวดหมู่",
    dataIndex: "document_category_id",
    key: "document_category_id",
  },
  {
    title: "จำนวน",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "ราคาต่อหน่วย",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "ราคารวม",
    dataIndex: "total_price",
    key: "total_price",
  },
];
