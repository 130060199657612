import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker } from "antd";
import AppTag from "components/AppTag";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

const { TextArea } = Input;

interface IRestaurant {
  name: string;
  address: string;
  embed_map: string;
  destination_visit_id: number;
  description: string;
  images: string[];
  contents: string;
}

export const CreateRestaurant: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const handleFinish = (values: any) => {
    console.log(values);
  };

  return (
    <Create title="เพิ่มร้านอาหาร" saveButtonProps={saveButtonProps}>
      <Form {...formProps} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label="ชื่อร้าน"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ที่อยู่"
          name={["address"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Embed a map"
          name={["embed_map"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label="คำบรรยายสั้นๆ"
          name={["desciption"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <UploadMultiple label="รูปภาพ" name={["images"]} />
        <Form.Item
          label="รายละเอียด"
          name={["contents"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
              {/* @ts-ignore */}

          <TextEditor />
        </Form.Item>
      </Form>
    </Create>
  );
};
