import axios, { AxiosInstance } from "axios";

export const API_URL = "https://api.aowtani.com";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

export default axiosInstance;
