import React, { useContext } from "react";
import JoditEditor from "jodit-react";
import { ColorModeContext } from "contexts/color-mode";

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value: string;
  placeholder: string;
  onChange: OnChangeHandler;
};

const TextEditor: React.FC<Props> = ({ value, onChange, placeholder }) => {
  const { mode } = useContext(ColorModeContext);

  const config = {
    toolbar: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 500,
    theme: mode,
  };

  return (
    <>
      <JoditEditor config={config} value={value} onBlur={onChange} />
    </>
  );
};

export default TextEditor;
