import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  ImageField,
} from "@refinedev/antd";
import { Space, Typography } from "antd";
import { API_URL } from "config/axios";

import ReviewPage from "components/ReviewPage";
import { getUserStatusString, ImageFallback } from "utils";

const { Title } = Typography;

export const ShowProfile: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={
          <div>
            ข้อมูลส่วนตัว{" "}
            <span
              dangerouslySetInnerHTML={{ __html: getUserStatusString() }}
            ></span>
          </div>
        }
      >
        <Title level={5}>โลโก้</Title>
        <ImageField
          width={150}
          value={API_URL + record?.logo?.asset}
          style={{ paddingBottom: 15 }}
          fallback={ImageFallback}
        />
        <Title level={5}>ชื่อธุรกิจ</Title>
        <TextField value={record?.name} />
        <Title level={5}>เฟสบุ๊ค</Title>
        <TextField value={record?.facebook} />
        <Title level={5}>เบอร์โทร</Title>
        <TextField value={record?.phone} />
        <Title level={5}>ที่อยู่</Title>
        <TextField value={record?.address} />
        <Title level={5}>แผนที่</Title>
        <div dangerouslySetInnerHTML={{ __html: record?.embed_map }} />
        <Title level={5}>คำบรรยายสั้นๆ</Title>
        <TextField value={record?.detail} />
        <Title level={5}>รูปภาพ</Title>
        <Space>
          {record?.images.map((img: any) => (
            <ImageField width={150} value={API_URL + img?.asset} />
          ))}
        </Space>
        <Title style={{ paddingTop: 15 }} level={5}>
          รายละเอียด
        </Title>
        <div dangerouslySetInnerHTML={{ __html: record?.content }} />
      </Show>
      <ReviewPage resource="business_partners" />
    </>
  );
};
