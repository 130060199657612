import React, { useState } from "react";
import { Card, Empty } from "antd";
import { Area } from "@ant-design/charts";
import { SelectDate } from "./SelectDate";
import { useList } from "@refinedev/core";
import { PriceFormat } from "utils";

const user_id = JSON.parse(
  localStorage.getItem("permission") || "{}"
).app_able_id;

export const IncomeExpenseCard: React.FC = () => {
  const [dateString, setDateString] = useState("");
  const { data: graph } = useList({
    resource: `${user_id}/partner_analytics/graph?${dateString}`,
    queryOptions: {
      select: (data: any) => {
        return data.data;
      },
    },
  });

  const config = {
    data: graph?.merge_graph,
    xField: "label",
    yField: "value",
    stack: true, // Try to remove this line.
    seriesField: "name",
    legend: {
      itemName: {
        formatter: (text: any, item: any) => {
          return `${text === "Income" ? "รายรับ" : "รายจ่าย"}ทั้งหมด`;
        },
      },
      itemValue: {
        formatter: (text: any, item: any) => {
          return text === "Income"
            ? PriceFormat(graph?.total_incomes)
            : PriceFormat(graph?.total_expenses);
        },
      },
    },
  };

  return (
    <Card bordered={false} title="รายได้และค่าใช้จ่ายตามเอกสาร">
      <SelectDate onChange={setDateString} />
      {!graph && "กำลังโหลดกราฟ"}
      {graph?.merge_graph.length > 0 ? (
        <Area {...config} />
      ) : (
        <Empty description="ไม่มีข้อมูล" />
      )}
    </Card>
  );
};
