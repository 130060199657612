import {
  DashboardOutlined,
  DollarOutlined,
  MoneyCollectOutlined,
  UserOutlined,
} from "@ant-design/icons";
const permission = JSON.parse(localStorage.getItem("permission") || "{}");

export const TourResource = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "แดชบอร์ด",
      icon: <DashboardOutlined />,
    },
  },
  {
    name: "tours",
    list: "/tours",
    meta: {
      label: "กิจกรรมทัวร์",
    },
  },
  {
    name: `${permission.app_able_id}/packages`,
    list: `/packages`,
    create: `/packages/create`,
    edit: `/packages/edit/:id`,
    show: `/packages/show/:id`,
    meta: {
      parent: "tours",
      label: "แพ็คเกจ",
    },
  },
  {
    name: `${permission.app_able_id}/bookings`,
    list: `/bookings`,
    edit: `/bookings/edit/:id`,
    show: `/bookings/show/:id`,
    meta: {
      parent: "tours",
      label: "การจอง",
    },
  },
  {
    name: `business_partners`,
    list: `/business_partners/show/${permission.app_able_id}`,
    edit: `/business_partners/edit/${permission.app_able_id}`,
    meta: {
      label: "โปรไฟล์",
      icon: <UserOutlined />,
    },
  },
  {
    name: `${permission.app_able_id}/bank_accounts`,
    list: "/bank_accounts",
    create: "/bank_accounts/create",
    edit: "/bank_accounts/edit/:id",
    show: "/bank_accounts/show/:id",
    meta: {
      label: "ตั้งค่าบัญชีรับเงิน",
      icon: <MoneyCollectOutlined />,
    },
  },
  {
    name: `finance`,
    meta: {
      label: "การเงิน",
      icon: <DollarOutlined />,
    },
  },
  {
    name: `${permission.app_able_id}/incomes`,
    list: `/tour/incomes`,
    create: "/tour/incomes/create",
    edit: "/tour/incomes/edit/:id",
    show: `/tour/incomes/show/:id`,
    meta: {
      label: "รายรับ",
      parent: "finance",
    },
  },
  {
    name: `${permission.app_able_id}/expenses`,
    list: `/tour/expenses`,
    create: "/tour/expenses/create",
    edit: "/tour/expenses/edit/:id",
    show: `/tour/expenses/show/:id`,
    meta: {
      label: "รายจ่าย",
      parent: "finance",
    },
  },
  {
    name: "tani_events",
    list: "/events",
    create: "/events/create",
    edit: "/events/edit/:id",
    show: "/events/show/:id",
    meta: {
      label: "กิจกรรม",
    },
  },
];
