import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

export const EditBankAccount: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="ธนาคาร"
          name={["slug"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="เลือกธนาคาร"
            options={[
              { value: "ธนาคารกสิกรไทย" },
              { value: "ธนาคารไทยพาณิชย์" },
              { value: "ธนาคารกรุงไทย" },
              { value: "ธนาคารทหารไทย" },
              { value: "ธนาคารทีเอ็มบี" },
              { value: "ธนาคารกรุงศรีอยุธยา" },
              { value: "ธนาคารธนชาต" },
              { value: "ธนาคารเกียรตินาคิน" },
              { value: "ธนาคารออมสิน" },
              { value: "ธนาคารอิสลามแห่งประเทศไทย" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="เลขบัญชี"
          name={["account_number"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ชื่อบัญชี"
          name={["account_name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="สาขา"
          name={["account_branch"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
