import { useList } from "@refinedev/core";
import { Card } from "antd";
import React from "react";

const user_id = JSON.parse(
  localStorage.getItem("permission") || "{}"
)?.app_able_id;

function ViewCard() {
  const { data } = useList({
    resource: `${user_id}/partner_analytics/view_count`,
    queryOptions: {
      select: (data: any) => {
        return data.data;
      },
    },
  });

  return (
    <Card bordered={false} title="Transaction">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>จำนวนยอดผู้เข้าชม (ครั้ง)</h3>
        <h3>{data?.total_view}</h3>
      </div>
    </Card>
  );
}

export default ViewCard;
