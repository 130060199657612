import React, { useEffect, useMemo, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, NumberField } from "@refinedev/antd";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  InputNumber,
  Flex,
  DatePicker,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { IncomeItemsType } from "types";

import styles from "./Table.module.css";

const { TextArea } = Input;

export const IncomesCreate: React.FC<IResourceComponentsProps> = () => {
  const [Total, setTotal] = useState(0);
  const { formProps, saveButtonProps, form, onFinish } = useForm();

  const income_items: IncomeItemsType[] = Form.useWatch("income_items", form);
  const discount = Form.useWatch("discount", form);

  const newIncomesItems = useMemo(() => {
    if (income_items && income_items.length > 0) {
      const data = income_items.map((item) => {
        if (item !== undefined) {
          item.total_price = item.quantity * item.price;
          return item;
        }
      });

      const sum_total = income_items.reduce((acc, currentObject) => {
        if (currentObject !== undefined) {
          return acc + currentObject.total_price;
        }
        return 0;
      }, 0);

      setTotal(sum_total);

      return data;
    }
  }, [income_items]);

  form.setFieldValue("income_items", newIncomesItems);

  useEffect(() => {
    const total = Total;
    form.setFieldValue("total", total);
  }, [Total]);

  const handleSubmit = async (values: any) => {
    const payload = {
      income: {
        ...values,
        income_items: income_items ?? [],
      },
    };
    await onFinish(payload);
  };

  return (
    <Create saveButtonProps={saveButtonProps} title="สร้างรายรับ">
      <Form {...formProps} onFinish={handleSubmit}>
        <Row gutter={30}>
          <Col md={12}>
            <Form.Item
              label="ชื่อลูกค้า"
              name={["customer_name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="วันที่"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="รายละเอียดลูกค้า"
              name={["customer_detail"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="จำนวนเงินรวมทั้งสิ้น"
              name={["total"]}
              initialValue={0}
            />
            <NumberField
              value={Total - discount}
              style={{
                fontSize: 48,
                color: "#1890FF",
                fontWeight: "700",
                lineHeight: "0",
                paddingBottom: 30,
              }}
            />
            <br />
            <br />
          </Col>
          <Col md={12}>
            <Form.Item
              label="ชื่อโปรเจ็ค"
              name={["project_name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="เลขที่อ้างอิง"
              name={["ref_no"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              label="รายละเอียดโปรเจ็ค"
              name={["project_detail"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.List name="income_items">
              {(fields, { add, remove }) => (
                <>
                  <table className={styles.table}>
                    <tr>
                      {columns.map((column) => (
                        <th key={column.key}>{column.title}</th>
                      ))}
                    </tr>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <tr key={key}>
                        <td>
                          {key + 1}
                          <Form.Item
                            {...restField}
                            name={[name, "key"]}
                            initialValue={key}
                            hidden
                          />
                        </td>
                        <td>
                          <Form.Item {...restField} name={[name, "detail"]}>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "quantity"]}
                            initialValue={1}
                          >
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "price"]}
                            initialValue={0}
                          >
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, "total_price"]}
                            initialValue={0}
                            hidden
                          >
                            <InputNumber disabled />
                          </Form.Item>
                          <NumberField
                            value={income_items[index]?.total_price}
                          />
                        </td>
                        <div
                          style={{
                            position: "absolute",
                            top: 15,
                            marginLeft: 8,
                          }}
                        >
                          <DeleteOutlined
                            onClick={() => remove(name)}
                            style={{ color: "red" }}
                          />
                        </div>
                      </tr>
                    ))}
                  </table>
                  {fields.length === 0 && (
                    <div style={{ textAlign: "center", padding: "15px 0" }}>
                      ยังไม่มีรายการ
                    </div>
                  )}
                  <Flex
                    justify="space-between"
                    style={{ marginTop: 15, marginBottom: 30 }}
                  >
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      เพิ่มแถวรายการ
                    </Button>
                  </Flex>
                </>
              )}
            </Form.List>
          </Col>
          <Col md={24}>
            <Flex justify="end" vertical align="end">
              <Form.Item
                label="ส่วนลดพิเศษ"
                name={["discount"]}
                rules={[
                  {
                    required: false,
                  },
                ]}
                initialValue={0}
              >
                <InputNumber style={{ width: 300 }} />
              </Form.Item>
              <Form.Item
                label="หมายเหตุ"
                name={["note"]}
                rules={[
                  {
                    required: false,
                  },
                ]}
                initialValue=""
              >
                <TextArea style={{ width: 300 }} />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

const columns = [
  {
    title: "ลำดับ",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "รายละเอียด",
    dataIndex: "detail",
    key: "detail",
  },
  {
    title: "จำนวน",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "ราคาต่อหน่วย",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "ราคารวม",
    dataIndex: "total_price",
    key: "total_price",
  },
];
