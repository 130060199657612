import {
  UserOutlined,
  DollarOutlined,
  CalendarOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
const other_id = localStorage.getItem("other_id");
export const otherResource = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "แดชบอร์ด",
      icon: <DashboardOutlined />,
    },
  },
  {
    name: `business_partners`,
    list: `/business_partners/show/${other_id}`,
    edit: `/business_partners/edit/${other_id}`,
    meta: {
      label: "ข้อมูลธุรกิจ",
      icon: <UserOutlined />,
    },
  },
  {
    name: `finance`,
    list: `/business_partners/edit/${other_id}`,
    meta: {
      label: "การเงิน",
      icon: <DollarOutlined />,
    },
  },
  {
    name: `${other_id}/incomes`,
    list: `/other/incomes`,
    create: "/other/incomes/create",
    edit: "/other/incomes/edit/:id",
    show: `/other/incomes/show/:id`,
    meta: {
      label: "รายรับ",
      parent: "finance",
    },
  },
  {
    name: `${other_id}/expenses`,
    list: `/other/expenses`,
    create: "/other/expenses/create",
    edit: "/other/expenses/edit/:id",
    show: `/other/expenses/show/:id`,
    meta: {
      label: "รายจ่าย",
      parent: "finance",
    },
  },
  {
    name: "tani_events",
    list: "/events",
    create: "/events/create",
    edit: "/events/edit/:id",
    show: "/events/show/:id",
    meta: {
      label: "กิจกรรม",
      icon: <CalendarOutlined />,
    },
  },
];
