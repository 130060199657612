import {
  UserOutlined,
  MoneyCollectOutlined,
  DashboardOutlined,
  DollarOutlined,
} from "@ant-design/icons";
const shop_id = localStorage.getItem("shop_id");
const permission = JSON.parse(localStorage.getItem("permission") || "{}");

export const Shop = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "แดชบอร์ด",
      icon: <DashboardOutlined />,
    },
  },
  {
    name: "shops",
    list: "/shops",
    meta: {
      label: "สินค้า",
    },
  },
  {
    name: `${shop_id}/products`,
    list: `/products`,
    create: `/products/create`,
    edit: `/products/edit/:id`,
    show: `/products/show/:id`,
    meta: {
      parent: "shops",
      label: "รายการสินค้า",
    },
  },
  {
    name: `orders`,
    list: `/orders`,
    create: `/orders/create`,
    edit: `/orders/edit/:id`,
    show: `/orders/show/:id`,
    meta: {
      parent: "shops",
      label: "การสั่งซื้อ",
    },
  },
  {
    name: `business_partners`,
    list: `/business_partners/show/${shop_id}`,
    edit: `/business_partners/edit/${shop_id}`,
    meta: {
      label: "โปรไฟล์",
      icon: <UserOutlined />,
    },
  },
  {
    name: `${shop_id}/bank_accounts`,
    list: "/bank_accounts",
    create: "/bank_accounts/create",
    edit: "/bank_accounts/edit/:id",
    show: "/bank_accounts/show/:id",
    meta: {
      label: "ตั้งค่าบัญชีรับเงิน",
      icon: <MoneyCollectOutlined />,
    },
  },
  {
    name: `finance`,
    list: `/business_partners/edit/${shop_id}`,
    meta: {
      label: "การเงิน",
      icon: <DollarOutlined />,
    },
  },
  {
    name: `${shop_id}/incomes`,
    list: `/shop/incomes`,
    create: "/shop/incomes/create",
    edit: "/shop/incomes/edit/:id",
    show: "/shop/incomes/show/:id",
    meta: {
      label: "รายรับ",
      parent: "finance",
    },
  },
  {
    name: `${shop_id}/expenses`,
    list: `/shop/expenses`,
    create: "/shop/expenses/create",
    edit: "/shop/expenses/edit/:id",
    show: "/shop/expenses/show/:id",
    meta: {
      label: "รายจ่าย",
      parent: "finance",
    },
  },
  {
    name: "tani_events",
    list: "/events",
    create: "/events/create",
    edit: "/events/edit/:id",
    show: "/events/show/:id",
    meta: {
      label: "กิจกรรม",
    },
  },
];
