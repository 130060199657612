import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, InputNumber } from "antd";
import AppTag from "components/AppTag";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

const { TextArea } = Input;

interface ISelect {
  id: number;
  name: string;
}

const org_id = localStorage.getItem("org_id");

export const EditPackage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const data = queryResult?.data?.data;

  const destinationVisit = useSelect<ISelect>({
    resource: `${org_id}/destination_visits`,
    optionLabel: "name",
    optionValue: "id",
  });
  const localGuide = useSelect<ISelect>({
    resource: `${org_id}/local_guides`,
    optionLabel: "name",
    optionValue: "id",
  });

  const handleFinish = (values: any) => {
    const payload = {
      package: {
        ...values,
      },
    };
    onFinish(payload);
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="แก้ไขชุมชน">
      <Form {...formProps} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="ID"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="ชื่อแพคเกจ"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="ประเภท" name={["types"]}>
          <AppTag onChange={(e) => e} />
        </Form.Item>
        <Form.Item
          label="รูปแบบ"
          name={["period"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="สถานที่ท่องเที่ยว"
          name={["destination_visit_id"]}
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={data?.destination_visit?.id}
        >
          <Select
            placeholder="เลือกสถานที่ท่องเที่ยว"
            {...destinationVisit.selectProps}
          />
        </Form.Item>
        <Form.Item
          label="ไกด์ท้องถิ่น"
          name={["local_guide_id"]}
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={data?.local_guide?.id}
        >
          <Select placeholder="เลือกไกด์ท้องถิ่น" {...localGuide.selectProps} />
        </Form.Item>

        <Form.Item
          label="คำบรรยายสั้นๆ"
          name={["desciption"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <UploadMultiple label="รูปภาพ" name={["images"]} value={data?.images} />
        <Form.Item
          label="รายละเอียด"
          name={["content"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          {/* @ts-ignore */}
          <TextEditor />
        </Form.Item>
        <Form.Item
          label="เงื่อนไข"
          name={["conditions"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          {/* @ts-ignore */}
          <TextEditor />
        </Form.Item>
        <Form.Item
          label="ราคาแพ็คเกจ"
          name={["price"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="ระบุราคา"
          />
        </Form.Item>
        <Form.Item
          label="ราคาก่อนลด"
          name={["price_before_discount"]}
          dependencies={["price"]}
          rules={[
            {
              required: false,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value > getFieldValue("price")) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("ราคาโปรโมชั่นต้องน้อยกว่าราคาปกติ")
                );
              },
            }),
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="ระบุราคา"
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
