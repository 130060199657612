import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

const shop_id = localStorage.getItem("shop_id");

export const ListOrder: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    resource: `orders?shop_id=${shop_id}`,
  });

  const status = [
    {
      type: "pending",
      label: "กำลังตรวจสอบ",
    },
    {
      type: "cancelled",
      label: "ยกเลิก",
    },
    {
      type: "approved",
      label: "จัดส่งแล้ว",
    },
  ];

  return (
    <List headerButtons={<></>}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["order_at"]}
          title="วันที่สั่งซื้อ"
          render={(value: any) => (
            <DateField value={value} format="D/MM/BBBB" />
          )}
        />
        <Table.Column dataIndex={["customer_name"]} title="ชื่อผู้สั่ง" />
        <Table.Column dataIndex={["customer_email"]} title="อีเมล์" />
        <Table.Column dataIndex={["customer_phone"]} title="เบอร์โทร" />
        <Table.Column dataIndex={["order_items"]} title="จำนวนรายการ" render={(item: any)=>item?.length}/>
        <Table.Column
          dataIndex="status"
          title="สถานะการสั่งซื้อ"
          render={(value) => status.find(({ type }) => type === value)?.label}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
