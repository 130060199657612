import React, { useState, useEffect } from "react";
import { useLogout, useNavigation } from "@refinedev/core";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from "./styles";
import { useSelect } from "@refinedev/antd";
import { Row, Col, Layout, Card, Form, Input, Button, Select } from "antd";
import axiosInstance from "config/axios";
import UploadOne from "components/UploadOne";
import UploadFile from "components/UploadFile";
import { HOTEL_TYPE, RESTAURANT_TYPE } from "utils/data";

const { TextArea } = Input;

export interface IRegisterForm {
  name: string;
  address: string;
  detail: string;
  facebook: string;
  phone: string;
  type: string;
  community_id: number;
}

interface ISelect {
  id: number;
  name: string;
}

const handleRegister = async (values: any) => {
  let payload = {
    business_partner: {
      ...values,
      community_id: parseInt(values.community_id),
    },
  };

  const response = await axiosInstance.post("/business_partners", payload);
  if (response.status === 201) {
    if (response.data.permission) {
      localStorage.setItem(
        "permission",
        JSON.stringify(response.data.permission)
      );
      localStorage.setItem(
        "identity",
        JSON.stringify({
          fullname: response.data.fullname,
          profile: response.data.profile,
        })
      );
    } else {
      localStorage.setItem("permission", JSON.stringify("{}"));
    }

    localStorage.setItem("bussiness_type", response.data.type);
    localStorage.setItem("bussiness_id", "BusinessPartner");
    if (response.data.type === "TourActivity")
      localStorage.setItem("tour_id", response.data.id);
    if (response.data.type === "Shop")
      localStorage.setItem("shop_id", response.data.id);
    if (response.data.type === "Hotel")
      localStorage.setItem("hotel_id", response.data.id);
    if (response.data.type === "Restaurant")
      localStorage.setItem("restaurant_id", response.data.id);
    if (response.data.type === "Other")
      localStorage.setItem("other_id", response.data.id);
    window.location.replace("/");
  }
};

export const RegisterOnboarding: React.FC = () => {
  const [orgId, setOrgId] = useState(0);

  const [form] = Form.useForm<IRegisterForm>();
  const typeValue = Form.useWatch("type", form);

  const selectOrg = useSelect<ISelect>({
    resource: "organizations",
    optionLabel: "name",
    optionValue: "id",
  });

  const { mutate: logout } = useLogout();

  const selectCommunity = useSelect<ISelect>({
    resource: `${orgId}/communities`,
    queryOptions: {
      enabled: orgId ? true : false,
    },
    optionLabel: "name",
    optionValue: "id",
  });

  useEffect(() => {
    form.resetFields(["type_details"]);
  }, [typeValue]);

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col>
          <Card
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
              ...containerStyles,
            }}
          >
            <Form<IRegisterForm>
              layout="vertical"
              form={form}
              onFinish={handleRegister}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <UploadOne
                    label="โปรไฟล์"
                    name="profile"
                    rules={[{ required: false }]}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="ชื่อ"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col
                  span={
                    typeValue === "Hotel" ||
                    typeValue === "Restaurant" ||
                    typeValue === "Others"
                      ? 12
                      : 24
                  }
                >
                  <Form.Item
                    name="type"
                    label="ประเภทธุรกิจ"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value="Hotel">ที่พัก</Select.Option>
                      <Select.Option value="Restaurant">
                        ร้านอาหาร
                      </Select.Option>
                      <Select.Option value="Shop">ร้านขายของ</Select.Option>
                      <Select.Option value="TourActivity">ทัวร์</Select.Option>
                      <Select.Option value="Others">อื่นๆ</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {(typeValue === "Hotel" ||
                  typeValue === "Restaurant" ||
                  typeValue === "Others") && (
                  <Col span={12}>
                    {typeValue === "Hotel" && (
                      <Form.Item
                        name="type_details"
                        label="ประเภทที่พัก"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          {HOTEL_TYPE.map((hotel) => (
                            <Select.Option value={hotel.value}>
                              {hotel.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    {typeValue === "Restaurant" && (
                      <Form.Item
                        name="type_details"
                        label="ประเภทร้านอาหาร"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          {RESTAURANT_TYPE.map((res) => (
                            <Select.Option value={res.value}>
                              {res.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    {typeValue === "Others" && (
                      <Form.Item
                        name="type_details"
                        label="กรอกประเภทอื่นๆ"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="ที่อยู่"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="detail"
                    label="รายละเอียด"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="facebook"
                    label="เฟสบุ๊ค"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    label="เบอร์โทร"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="organization_id"
                    label="องค์กรที่ตั้งของธุรกิจ"
                    rules={[{ required: true }]}
                  >
                    <Select
                      {...selectOrg.selectProps}
                      onSearch={undefined}
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(id: any) => setOrgId(id)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="community_id"
                    label="ชุมชนที่ตั้งของธุรกิจ"
                    rules={[{ required: false }]}
                  >
                    <Select
                      {...selectCommunity.selectProps}
                      onSearch={undefined}
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={!orgId}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="id_card_pdf"
                    label="สำเนาบัตรประชาชน"
                    rules={[{ required: false }]}
                    getValueFromEvent={(e: any) => e.file.response}
                  >
                    <UploadFile />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="house_registration_pdf"
                    label="สำเนาทะเบียนบ้าน "
                    rules={[{ required: false }]}
                    getValueFromEvent={(e: any) => e.file.response}
                  >
                    <UploadFile />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="commercial_registration_pdf"
                    label="เอกสารการจดทะเบียนร้านค้า/พาณิชย์"
                    rules={[{ required: false }]}
                    getValueFromEvent={(e: any) => e.file.response}
                  >
                    <UploadFile />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button type="primary" size="large" htmlType="submit" block>
                    บันทึก
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => logout()}
                    danger
                  >
                    ออกจากระบบ
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
