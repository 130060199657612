import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useMany,
  useNavigation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  MarkdownField,
  DateField,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space, Switch, Tooltip } from "antd";
import { MessageOutlined } from "@ant-design/icons";

import { date, getUserStatus } from "utils";
import { CHECK_USER_STATUS } from "config/app";

export const PackageList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();
  const { push } = useNavigation();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="created_at"
          title="วันที่สร้าง"
          render={(text) => date.thaiFormat(text)}
        />
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="name" title="ชื่อแพ็คเกจ" />
        <Table.Column dataIndex="period" title="รูปแบบแพ็คเกจ" />
        <Table.Column
          dataIndex="price_before_discount"
          title="ราคาปกติ"
          render={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
        <Table.Column
          dataIndex="price"
          title="ราคาโปรโมชั่น"
          render={(text) => (text ? `฿${text}` : "ไม่มี")}
        />
        <Table.Column
          dataIndex={["destination_visit", "name"]}
          title="สถานที่ท่องเที่ยว"
        />
        <Table.Column
          dataIndex={["local_guide", "name"]}
          title="ไกด์ท้องถิ่น"
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
