import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useCustomMutation,
} from "@refinedev/core";
import { Show, DateField, ImageField, NumberField } from "@refinedev/antd";
import { Descriptions, Button, Space, Select, Input, Table } from "antd";

import { API_URL } from "config/axios";

export const ShowOrder: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const [status, setStatus] = useState("");
  const [trackingCode, setTrackingCode] = useState("");

  const record = data?.data;

  const { mutate } = useCustomMutation();

  useEffect(() => {
    setStatus(record?.status);
    setTrackingCode(record?.tracking_code);
  }, [record]);

  const updateStatus = async () => {
    mutate({
      url: `/orders/update_status/${record?.id}`,
      method: "put",
      values: { status: status },
      successNotification: (data, values) => {
        return {
          message: `อัพเดทสถานะเรียบร้อยแล้ว`,
          type: "success",
        };
      },
    });
  };

  const updateTrackingCode = async () => {
    mutate({
      url: `/orders/${record?.id}`,
      method: "put",
      values: { order: { tracking_code: trackingCode } },
      successNotification: (data, values) => {
        return {
          message: `บันทึกเลขพัสดุเรียบร้อยแล้ว`,
          type: "success",
        };
      },
    });
  };

  const columns = [
    {
      title: "ไอดีสินค้า",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "SKU",
      dataIndex: ["product", "sku"],
      key: "sku",
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "จำนวน",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "ราคา",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "ราคารวม",
      dataIndex: "subtotal",
      key: "subtotal",
    },
    {
      title: "หมายเหตุ",
      dataIndex: "note",
      key: "note",
    },
  ];

  const getTotal = (value: any) => {
    console.log({ value });
    return value?.reduce((accumulator: number, currentValue: any) => {
      return accumulator + currentValue.subtotal;
    }, 0);
  };

  return (
    <Show isLoading={false} title="รายละเอียดการสั่ังซื้อ" canEdit={false}>
      <Descriptions
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>ข้อมูลผู้สั่ง</span>
            <Space>
              <span style={{ fontWeight: "600", fontSize: 16 }}>สถานะ : </span>
              <Select
                value={status}
                style={{ width: 200 }}
                onChange={setStatus}
                options={[
                  { value: "pending", label: "กำลังตรวจสอบ" },
                  { value: "approved", label: "จัดส่งแล้ว" },
                  { value: "cancelled", label: "ยกเลิก" },
                ]}
              />
              <Button type="primary" onClick={updateStatus}>
                บันทึก
              </Button>
            </Space>
          </div>
        }
        layout="vertical"
      >
        <Descriptions.Item label="ชื่อผู้สั่ง">
          {record?.customer_name}
        </Descriptions.Item>
        <Descriptions.Item label="อีเมล์">
          {record?.customer_email}
        </Descriptions.Item>
        <Descriptions.Item label="เบอร์โทรศัพท์">
          {record?.customer_phone}
        </Descriptions.Item>
        <Descriptions.Item label="วันที่สั่ง">
          <DateField value={record?.order_at} format="D/MM/BBBB" />
        </Descriptions.Item>
        <Descriptions.Item
          label="ที่อยู่ในการจัดส่ง"
          contentStyle={{ display: "flex", flexDirection: "column", gap: 15 }}
        >
          {record?.customer_address}
          <Space.Compact>
            <Input
              value={trackingCode}
              addonBefore="แจ้งเลขพัสดุ"
              onChange={(e) => setTrackingCode(e.target.value)}
            />
            <Button type="primary" onClick={updateTrackingCode}>
              บันทึก
            </Button>
          </Space.Compact>
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title="ข้อมูลสินค้า" layout="vertical" />
      <Table columns={columns} dataSource={record?.order_items} />
      <Descriptions layout="vertical">
        <Descriptions.Item label="การชำระเงิน (บาท)">
          {<NumberField value={getTotal(record?.order_items)} />}&nbsp;บาท
        </Descriptions.Item>
        <Descriptions.Item label="หลักฐานการชำระเงิน (สลิป)">
          {record?.slip ? (
            <ImageField value={API_URL + record?.slip?.asset} width={100} />
          ) : (
            "ยังไม่ได้ชำระเงิน"
          )}
        </Descriptions.Item>
      </Descriptions>
    </Show>
  );
};
