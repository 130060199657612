import {
  UserOutlined,
  DollarOutlined,
  CalendarOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
const hotel_id = localStorage.getItem("hotel_id");
export const HotelResource = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "แดชบอร์ด",
      icon: <DashboardOutlined />,
    },
  },
  {
    name: `business_partners`,
    list: `/business_partners/show/${hotel_id}`,
    edit: `/business_partners/edit/${hotel_id}`,
    meta: {
      label: "ข้อมูลที่พัก",
      icon: <UserOutlined />,
    },
  },
  {
    name: `finance`,
    list: `/business_partners/edit/${hotel_id}`,
    meta: {
      label: "การเงิน",
      icon: <DollarOutlined />,
    },
  },
  {
    name: `${hotel_id}/incomes`,
    list: `/hotel/incomes`,
    create: "/hotel/incomes/create",
    edit: "/hotel/incomes/edit/:id",
    show: `/hotel/incomes/show/:id`,
    meta: {
      label: "รายรับ",
      parent: "finance",
    },
  },
  {
    name: `${hotel_id}/expenses`,
    list: `/hotel/expenses`,
    create: "/hotel/expenses/create",
    edit: "/hotel/expenses/edit/:id",
    show: `/hotel/expenses/show/:id`,
    meta: {
      label: "รายจ่าย",
      parent: "finance",
    },
  },
  {
    name: "tani_events",
    list: "/events",
    create: "/events/create",
    edit: "/events/edit/:id",
    show: "/events/show/:id",
    meta: {
      label: "กิจกรรม",
      icon: <CalendarOutlined />,
    },
  },
];
