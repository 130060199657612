import { HotelResource } from "./hotel";
import { otherResource } from "./others";
import { RestaurantResource } from "./restaurant";
import { Shop } from "./shop";
import { TourResource } from "./tour";

export const resources = [
  {
    key: 1,
    type: "TourActivity",
    resources: TourResource,
  },
  {
    key: 2,
    type: "Restaurant",
    resources: RestaurantResource,
  },
  {
    key: 3,
    type: "Hotel",
    resources: HotelResource,
  },
  {
    key: 4,
    type: "Shop",
    resources: Shop,
  },
  {
    key: 4,
    type: "Other",
    resources: otherResource,
  },
];
