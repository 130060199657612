import { useOne } from "@refinedev/core";
import { API_URL } from "config/axios";
import { ColorModeContext } from "../contexts/color-mode";
import { useContext } from "react";
import { Image } from "antd";
import { ImageFallback } from "utils";

interface IUser {
  id: number;
  name: string;
  logo: {
    id: number;
    asset: string;
  };
}

const permission = JSON.parse(localStorage.getItem("permission") || "{}");

export default function AppIcon() {
  const { mode, setMode } = useContext(ColorModeContext);

  const { data } = useOne<IUser>({
    resource: "business_partners",
    id: permission ? permission.app_able_id : 0,
  });

  const org = data?.data;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Image
        style={{
          borderRadius: "50%",
          objectFit: "cover",
          border: "1px solid",
          width: 40,
          height: 40,
        }}
        src={API_URL + org?.logo?.asset}
        fallback={ImageFallback}
      />
      <h4
        style={{
          fontWeight: "bold",
          marginTop: 15,
          color: mode === "dark" ? "white" : "black",
        }}
      >
        {org?.name}
      </h4>
    </div>
  );
}
