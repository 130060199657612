export const HOTEL_TYPE = [
  {
    label: "โรงแรม",
    value: "โรงแรม",
  },
  {
    label: "รีสอร์ท",
    value: "รีสอร์ท",
  },
  {
    label: "โฮมสเตย์",
    value: "โฮมสเตย์",
  },
  {
    label: "ที่พักอื่นๆ",
    value: "ที่พักอื่นๆ",
  },
];

export const RESTAURANT_TYPE = [
  {
    label: "ร้านอาหารมาตรฐาน",
    value: "ร้านอาหารมาตรฐาน",
  },
  {
    label: "ร้านอาหารตามสั่งทั่วไป",
    value: "ร้านอาหารตามสั่งทั่วไป",
  },
  {
    label: "ร้านอาหารแนวบ้านๆ",
    value: "ร้านอาหารแนวบ้านๆ",
  },
  {
    label: "ร้านคาเฟ่",
    value: "ร้านคาเฟ่",
  },
  {
    label: "ร้านขนมหวาน",
    value: "ร้านขนมหวาน",
  },
];
