import React from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  MarkdownField,
  ImageField,
} from "@refinedev/antd";
import { Typography, Descriptions } from "antd";
import { API_URL } from "config/axios";
import ReviewPage from "components/ReviewPage";

const { Title } = Typography;

export const ShowShopProduct: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show isLoading={isLoading} title="แสดงรายการสินค้า">
        <Descriptions layout="vertical">
          <Descriptions.Item label="ชื่อสินค้า">
            <TextField value={record?.name} />
          </Descriptions.Item>
          <Descriptions.Item label="SKU">
            <TextField value={record?.sku} />
          </Descriptions.Item>
          <Descriptions.Item label="จำนวนคงคลัง">
            <NumberField value={record?.stock ?? ""} />
          </Descriptions.Item>
          <Descriptions.Item
            label="รูปภาพ"
            span={3}
            contentStyle={{ display: "flex", gap: 12 }}
          >
            {record?.images?.map((item: any) => (
              <ImageField
                key={item.id}
                width={80}
                value={API_URL + item.asset}
              />
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="ราคาสินค้า">
            <NumberField value={record?.price ?? ""} />
          </Descriptions.Item>
          <Descriptions.Item label="ราคาก่อนลด" span={2}>
            <NumberField value={record?.price_before_discount ?? ""} />
          </Descriptions.Item>
          <Descriptions.Item label="รายละเอียด" span={3}>
            <div dangerouslySetInnerHTML={{ __html: record?.details }} />
          </Descriptions.Item>
        </Descriptions>
      </Show>
      <ReviewPage resource="products" />
    </>
  );
};
