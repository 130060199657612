import React, { useState } from "react";

import {
  Card,
  Row,
  Col,
  Space,
  Avatar,
  Rate,
  Image,
  Button,
  Input,
} from "antd";
import UploadMultiple from "components/UploadMultiple";

export const ReviewList: React.FC = () => {
  const [reply, setReply] = useState<number | null>(null);
  const review_list = [
    {
      id: 1,
      profile: {
        asset:
          "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        id: 1,
      },
      name: "Han Solo",
      createdAt: "23/7/2023 18.00",
      score: 3,
      details:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure).",
      images: [
        {
          asset:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
          id: 1,
        },
        {
          id: 2,
          asset:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
        {
          id: 3,
          asset:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
      ],
      hide: false,
    },
    {
      id: 2,
      profile: {
        asset:
          "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        id: 1,
      },
      name: "Han Solo",
      createdAt: "23/7/2023 18.00",
      score: 3,
      details:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure).",
      images: [
        {
          asset:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
          id: 1,
        },
        {
          id: 2,
          asset:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
        {
          id: 3,
          asset:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
      ],
      hide: false,
    },
  ];
  return (
    <Card title="ความคิดเห็น">
      {review_list.map((item) => (
        <Row style={{ paddingBottom: 16 }}>
          <Col span={1}>
            <Avatar style={{ marginTop: 8 }} src={item.profile?.asset} />
          </Col>
          <Col span={22}>
            <Space direction="vertical" style={{ gap: 0 }}>
              <Space>
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  {item.name}
                </span>
                <span>{item.createdAt}</span>
              </Space>
              <Rate disabled defaultValue={3} />
              <p>{item.details}</p>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) =>
                    console.log(
                      `current index: ${current}, prev index: ${prev}`
                    ),
                }}
              >
                <Space>
                  {item.images.map((img) => (
                    <Image width={80} src={img?.asset} />
                  ))}
                </Space>
              </Image.PreviewGroup>
            </Space>
            {item.id === reply ? (
              <div style={{ paddingTop: 16 }}>
                <Space direction="vertical">
                  <Input width={300} placeholder="เพิ่มความคิดเห็น" />
                  <UploadMultiple name="images" label="" />
                  <Button
                    style={{ position: "relative", top: -16 }}
                    type="primary"
                  >
                    บันทึก
                  </Button>
                </Space>
              </div>
            ) : (
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.45)",
                  fontStyle: "italic",
                  paddingTop: 16,
                  cursor: "pointer",
                }}
                onClick={() => setReply(item.id)}
              >
                <span>ตอบกลับ...</span>
              </div>
            )}
          </Col>
          <Col span={1}>
            <Button type="link">ซ่อน</Button>
          </Col>
        </Row>
      ))}
    </Card>
  );
};
