import { UserOutlined } from "@ant-design/icons";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Avatar,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";
import React, { useContext } from "react";
import { ColorModeContext } from "../../contexts/color-mode";
import { API_URL } from "config/axios";
import { useGetIdentity } from "@refinedev/core";

const { Text } = Typography;
const { useToken } = theme;

type IUser = {
  id: number;
  fullname: string;
  profile: {
    asset: string;
  };
};

const user: IUser = JSON.parse(localStorage.getItem("identity") || "{}");

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
}) => {
  const { token } = useToken();
  const { mode, setMode } = useContext(ColorModeContext);

  // const { data: user } = useGetIdentity<IUser>();

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (isSticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        <Switch
          checkedChildren="Dark Mode 🌛"
          unCheckedChildren="Light Mode 🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        />
        <Space style={{ marginLeft: "8px" }} size="middle">
          {user?.fullname && <Text strong>{user?.fullname}</Text>}
          {user?.profile ? (
            <Avatar src={API_URL + user?.profile?.asset} alt={user?.fullname} />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};
